<template>
  <layout>
    <template slot="main">
      <v-card>
        <crud
          :modal="tetikle"
          :gelen_id="gelen_id"
          @hideDialog="showClose()"
        ></crud>
        <v-card-title>
          Biten Görevler
          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="primary"
            @click="
              tetikle = true;
              gelen_id = null;
            "
          >
            Yeni Görev
          </v-btn>
        </v-card-title>
        <datatables :headers="headers" :items="gorevler">
          <template v-slot:dt_actions="{ item }">
            <v-icon small class="mr-2" @click="hareketleriGoster(item.id)"
              >mdi-eye-arrow-right-outline</v-icon
            >
            <v-icon small class="mr-2" @click="updateDialog(item.id)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:dt_olusturma_tarihi="{ item }">
            {{ item.olusturma_tarihi | moment("DD-MM-YYYY HH:mm") }}
          </template>
        </datatables>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Crud from "../Gorevler/Crud.vue";
import Datatables from "../../components/datatables/index.vue";
import { bitenGorevListesi } from "../../query/gorevler";
import Layout from "@/components/Layout";
export default {
  components: { Crud, Datatables, Layout },

  async mounted() {
    this.gorevler = await bitenGorevListesi();
  },

  methods: {
    showClose() {
      this.tetikle = false;
    },
    updateDialog(item) {
      console.log(item);
      this.gelen_id = item;
      this.tetikle = true;
    },
    hareketleriGoster(id) {
      console.log(id);
      this.$router.push({
        name: "Hareketler",
        params: {
          id: id,
        },
      });
    },
  },

  watch: {
    async tetikle(val) {
      if (val === false) {
        this.gorevler = await bitenGorevListesi();
      }
    },
  },

  data() {
    return {
      gorevler: [],
      tetikle: false,
      gelen_id: null,
      options: {},
      headers: [
        {
          text: "Kayıt No",
          value: "kayit_no",
          filterType: "string",
        },
        {
          text: "Firma",
          value: "unvan",
          filterType: "string",
        },
        {
          text: "Konu",
          value: "konu",
          filterType: "string",
        },
        {
          text: "Durum",
          value: "aciliyet_durumu",
          filterType: "string",
        },
        {
          text: "Oluşturma Tarihi",
          value: "olusturma_tarihi",
          filterType: "string",
          hasSlot: true,
        },
        {
          text: "Kapanma Tarihi",
          value: "olusturma_tarihi",
          filterType: "string",
        },
        {
          text: "İŞLEMLER",
          value: "actions",
          hasSlot: true,
        },
      ],
    };
  },
};
</script>
